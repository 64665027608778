$order-summary__subtitle-font-size      : $font-size-medium;
$order-summary__subtitle-font-weight    : $font-weight-medium;

// Tab
$order-summary__tab-title-padding       : $spacer--extra-large 0 $spacer--small 0;
$order-summary__tab-title-border-top    : none;
$order-summary__tab-text-font-weight    : $font-weight-normal;
$order-summary__tab-icon-width          : 24px;
$order-summary__tab-icon-height         : 24px;

$order-summary__modal-input-min-width   : 108px;