.customer-account-create {
    .registration-fields {
        &__field {
            &--hidden {
                display: none;
            }
            &--upload {
                .mage-error {
                    order: 1;
                }
            }
        }
    }

    .customer-registration-terms {
        &__link {
            color: $color-primary;
            text-decoration: underline;
        }
    }

    .customer-application-nav {
        width: 80%;
        margin: 0 auto;
        @include mq(false, $screen-l) {
            width: 100%;
        }
        ul {
            display: flex;
            padding: 0;
            @include mq(false, $screen-s) {
                display: block;
            }
            li {
                width: 33.33%;
                text-align: center;
                list-style: none;
                background-color: #eaeaea;
                border: 1px solid #d8d8d8;
                cursor: pointer;
                padding: 20px 0;
                position: relative;
                @include mq(false, $screen-s) {
                    width: 100%;
                    text-align: left;
                    padding-left: 20px;
                }
                span {
                    background: #666;
                    width: 22px;
                    height: 22px;
                    border-radius: 50%;
                    display: inline-block;
                    color: #fff;
                    text-align: center;
                    margin-right: 5px;
                }
                &.active {
                    span {
                        background: #D71920;
                    }
                }
            }
        }
    }

    .step-content {
        display: none;
        width: 100%;
        max-width: 432px;
        &.active {
            display: block;
        }
    }
}