// List item;
$dropdown-list__item-font-size                         : $font-size-medium;

// Label
$dropdown-list__label-text-transform                   : none;
$dropdown-list__label-font-weight                      : $font-weight-normal;
$dropdown-list__label-color-hover                      : $color-primary;
$dropdown-list__label-text-decoration-hover            : underline;

// List icon
$dropdown-list__icon-fill                              : $color-secondary;
$dropdown-list__icon-fill--open                        : $color-secondary;

// With nested variant
$dropdown-list__label-font-size--with-nested           : $font-size-medium;
$dropdown-list__label-font-weight--with-nested         : $font-weight-normal;

$dropdown-list__icon-fill--with-nested                 : $gray-darker;

// Inner variant
$dropdown-list__label-color--inner                     : $gray-darker;
$dropdown-list__label-font-size--inner                 : $font-size-medium;

// detailed-content list variables
$dropdown-list__label-font-size--detailed-content      : $font-size-medium;

// buttons list variables
$dropdown-list__text-font-size--buttons                : $font-size-medium;
