$footer__background-color                                  : $white;
$footer__border                                            : none;
$footer__border-width                                      : 0;

$footer__handler-padding                                   : $spacer--medium 0;
$footer__handler-padding\@medium                           : $spacer--medium 0;
$footer__handler-padding\@large                            : $spacer--medium 0;

$footer__newsletter-width\@large                           : 100%;
$footer__newsletter-padding\@medium                        : $spacer--medium $spacer--extra-large 0 $spacer--semi-medium;
$footer__newsletter-padding\@large                         : $spacer--medium $spacer--extra-large 0 $spacer--semi-medium;

$footer__newsletter-border                                 : 1px solid $gray-light;
$footer__newsletter-border-width                           : 0 0 1px 0;
$footer__newsletter-display                                : flex;

$footer__link-display                                      : block;
$footer__link-font-size                                    : $font-size-base;
$footer__link-line-height                                  : 48px;

$footer__links-padding                                     : $spacer--semi-large $spacer--extra-large $spacer--large;
$footer__links-padding\@medium                             : $spacer--semi-large 0 $spacer--large;
$footer__links-padding\@large                              : $spacer--semi-large 0 $spacer--large;
$footer__links-dropdown-background                         : $white;

$footer__links-dropdown-list-margin                        : 0;
$footer__links-dropdown-list-padding\@medium               : 0;
$footer__links-dropdown-list-padding\@large                : 0;
$footer__links-dropdown-list-padding\@extra-large          : 0;
$footer__links-dropdown-list-border                        : none;
$footer__links-dropdown-list-item-width-first-child\@medium: 100%;
$footer__links-dropdown-list-item-border                   : none;
$footer__links-dropdown-list-item-padding                  : 0;

$footer__links-list-item-min-width\@medium                 : 200px;
$footer__links-list-item-min-width\@extra-large            : 204px;
$footer__links-list-padding                                : 0;
$footer__links-list-item-padding                           : 0;

$footer__social-list-title-align-self\@large               : unset;

$footer__social-list-order                                 : 0;
$footer__social-list-order\@medium                         : 1;

$footer__bottom-bar-background                             : $gray-darker;

$footer__bottom-padding                                    : $spacer--medium $spacer;
$footer__bottom-padding\@medium                            : $spacer--medium 0;
$footer__bottom-padding\@large                             : $spacer--medium $spacer--semi-large;

$footer__copywrite-order                                   : 1;
$footer__copywrite-order\@medium                           : 0;
$footer__copywrite-padding                                 : 0;
$footer__copywrite-padding\@medium                         : 0;
$footer__copywrite-font-size                               : $font-size-base;
$footer__copywrite-line-height                             : 48px;


$footer__social-list-align-self\@large                     : unset;

$footer__social-list__icon-link-margin\@large              : 0;
$footer__social-list__icon-link-bg                         : transparent;
$footer__social-list__icon-link-bg-hover                   : $white;
$footer__social-list__social-icon-fill                     : $white;
$footer__social-list__social-icon-fill-hover               : $color-secondary;
