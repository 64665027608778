// BLOG
$blog-layout-shift-width                            : 1344px;
$blog-layout-margin                                 : $spacer--medium $spacer 0;
$blog-layout-margin\@medium                         : $spacer--semi-large $spacer 0;
$blog-layout-margin\@large                          : $spacer--semi-medium $spacer--semi-large 0;
$blog-layout-margin\@extra-large                    : $spacer--semi-medium auto 0;

$blog-heading-font-size                             : $font-size-extra-large;
$blog-heading-font-size\@medium                     : 40px;
$blog-heading-font-size\@extra-large                : 48px;
$blog-heading-letter-spacing                        : -0.1px;
$blog-heading-letter-spacing\@medium                : -0.17px;
$blog-heading-letter-spacing\@extra-large           : -0.2px;

// BLOG GRID
$grid-container-columns-display\@medium             : 2 368px;
$grid-container-columns-display\@large              : 3 304px;
$grid-container-columns-gap                         : $spacer--medium;

$grid-item-margin                                   : 0 0 $spacer--semi-medium;
$grid-item-image-margin                             : 0 0 $spacer--semi-medium;

$grid-item-top-font-size                            : $font-size-small;
$grid-item-top-line-height                          : $spacer--medium;
$grid-item-top-letter-spacing                       : 1px;
$grid-item-top-margin                               : 0 0 $spacer--medium;
$grid-item-top-padding                              : 0 $spacer--medium 0;
$grid-item-top-padding\@extra-large                 : 0 $spacer--semi-medium 0;
$grid-item-top-date-color                           : rgba(0, 0, 0, 0.4);

$grid-item-content-padding                          : 0 $spacer--medium 0;
$grid-item-content-padding\@extra-large             : 0 $spacer--semi-medium 0;
$grid-item-content-title-margin                     : 0 0 $spacer--semi-medium;
$grid-item-content-description-margin               : 0 0 $spacer;
$grid-item-content-link-line-height                 : $spacer--large;
$grid-item-content-link-line-letter-spacing         : -0.24px;
$grid-item-content-link-line-letter-spacing\@medium : -0.38px;
$grid-item-content-title-font-size                  : $font-size-large;
$grid-item-content-title-font-size\@medium          : 28px;

$grid-item-bottom-nav-line-height                   : $spacer--extra-large;
$grid-item-bottom-nav-padding                       : $spacer--small;
$grid-item-bottom-nav-margin                        : 0 0 $spacer--medium;
$grid-item-bottom-nav-title-margin                  : 0 $spacer 0;
$grid-item-bottom-nav-svg-offset                    : 2px;

// BLOG NAV
$nav-category-link-color                            : rgba(0, 0, 0, 0.44);
$nav-category-link-line-height                      : $spacer--extra-large;
$nav-category-link-letter-spacing                   : 1.07px;

// BLOG SIDEBAR
// Line below overwrite !important in Snowdog_Components
$sidebar-search-input-border                        : none !important; // sass-lint:disable-line no-important
$sidebar-search-input-letter-spacing                : 0.93px;
$sidebar-search-input-line-height                   : $spacer--extra-large;
$sidebar-search-svg-width                           : $spacer--semi-medium;
$sidebar-search-min-width                           : 220px;
$sidebar-search-padding-left                        : $spacer--small;

$sidebar-form-input-flex-grow                       : 1;

// BLOG POST
$post-base-padding                                  : 0 $spacer--medium 0;
$post-base-padding\@medium                          : 0 128px 0;
$post-base-padding\@large                           : 0 160px 0;
$post-base-padding\@extra-large                     : 0 336px 0;

$post-main-padding                                  : $spacer--semi-large 0 $spacer--extra-large;

$post-top-font-size                                 : $font-size-small;
$post-top-line-height                               : $spacer--medium;
$post-top-letter-spacing                            : 1px;
$post-top-margin                                    : 0 0 $spacer--semi-medium;
$post-top-date-color                                : rgba(0, 0, 0, 0.4);

$post-image-padding\@medium                         : 0 $spacer--super-large;
$post-image-padding\@large                          : 0 80px;
$post-image-padding\@extra-large                    : 0 224px;
$post-image-margin                                  : 0 0 40px;

$post-title-font-size                               : $font-size-extra-large;
$post-title-font-size\@medium                       : $spacer--semi-large;
$post-title-line-height                             : $spacer--large;
$post-title-line-height\@medium                     : $spacer--extra-large;
$post-title-letter-spacing                          : -0.1px;
$post-title-letter-spacing\@medium                  : -0.17px;
$post-title-margin                                  : 0 0 $spacer--large;
$post-title-margin\@medium                          : 0 0 40px;

$post-content-text-margin                           : 0 0 $spacer--large;

$post-tags-margin                                   : 0 0 $spacer--semi-large;
$post-tags-separator-margin                         : 0 $spacer--extra-small;
$post-tags-line-height                              : $spacer--extra-large;

$social-padding                                     : 0 $font-size-medium 0;
$social-title-line-height                           : $spacer--small;
$social-title-margin                                : 0 0 $spacer;
$social-item-width                                  : $spacer--extra-large;
$social-item-height                                 : $spacer--extra-large;
$social-item-margin                                 : 0 $spacer;
$social-icon-width                                  : $spacer--semi-medium;
$social-icon-height                                 : $spacer--semi-medium;

// BLOG MIXINS
@mixin blog-post-content-styles {
    font-size: $font-size-base;
    line-height: $spacer--semi-medium;
    color: $font-color-base;
    font-family: $font-family-sans-serif;

    @include mq($screen-m) {
        font-size: $font-size-medium;
    }
}
