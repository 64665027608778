$teaser-teriary-heading-line-height           : $spacer--semi-large;
$teaser-teriary-heading-line-spacing          : -0.67px;
$teaser-teriary-heading-font-color            : $font-color-base;
$teaser-teriary-heading-padding               : 0;
$teaser-teriary-heading-margin                : $spacer--large 0 $spacer--semi-medium;

$teaser-teriary-description-font-size         : $font-size-base;
$teaser-teriary-description-line-height       : $spacer--semi-medium;
$teaser-teriary-description-margin            : 0 0 $spacer--semi-medium;

$teaser-teriary-link-padding                  : 0 $spacer--small;
$teaser-teriary-link-icon-fill                : $white;
$teaser-teriary-link-icon-margin              : 0;

$teaser-teriary-content-block-position        : relative;
$teaser-teriary-content-block-align           : flex-end;
$teaser-teriary-content-block-background-color: transparent;
$teaser-teriary-content-block-margin          : $spacer--super-large $spacer--semi-medium $spacer--semi-medium;
$teaser-teriary-content-block-margin-m        : 96px $spacer--semi-large 72px;
