.room-nav {
    &__title {
        &-wrapper {
            margin: $room-nav-title-margin;

            @include mq($screen-l) {
                margin: $room-nav-title-margin\@large;
            }
        }

        &-text {
            font-size: $font-size-extra-large;
            line-height: $room-nav-title-line-height;
            letter-spacing: $room-nav-title-letter-spacing;
            text-align: center;
            font-family: $font-family-serif;
            color: $font-color-base;

            @include mq($screen-m) {
                font-size: $room-nav-title-font-size\@medium;
                line-height: $spacer--extra-large;
                letter-spacing: $room-nav-title-latter-spacing;
            }

            @include mq($screen-xl) {
                font-size: $room-nav-title-font-size\@extra-large;
            }
        }
    }

    &__items {
        display: flex;
        flex-direction: row;
        justify-content: center;
        flex-wrap: wrap;
        list-style-type: none;
        margin: $room-nav-items-margin;
        padding: 0;
        overflow-x: auto;
        overflow-y: hidden;
        width: 100%;
        white-space: nowrap;
        max-width: 100%;

        &::-webkit-scrollbar { // sass-lint:disable-line no-vendor-prefixes
            display: none;
        }

        @include mq($screen-l) {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: $spacer--medium; // sass-lint:disable-line no-misspelled-properties
        }
    }

    &__item {
        min-height: $spacer--super-large;
        max-width: $room-nav-item-max-width;

        @include mq($screen-l) {
            max-width: $room-nav-item-max-width\@large;
        }

        &:hover,
        &:focus-visible,
        .focus-visible {
            background-color: $gray-lighter;
        }
    }

    &__link {
        font-size: $font-size-base;
        min-height: $spacer--super-large;
        width: 100%;
        height: 100%;
        padding: $spacer $spacer--medium;
        color: $gray-dark;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: nowrap;
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: $font-weight-bold;

        @include mq($screen-m) {
            font-size: $font-size-medium;
            line-height: $room-nav-link-line-height;
            letter-spacing: $room-nav-link-letter-spacing;
            width: 100%;
            max-height: $room-nav-link-max-height;
        }

        &--current {
            background-color: $white;
            color: $font-color-base;
        }
    }
    /*
        DO NOT DISPLAY EMPTY SIDEBAR FIELD
        WHEN IN SHOP BY ROOM CATEGORIES
    */
    ~ .catalog-list {
        display: flex;
        margin: 0;

            @include mq($screen-m) {
                margin: 0;
            }

            @include mq($screen-l) {
                margin: $spacer--semi-medium 0 0;
            }

            @include mq($screen-xl) {
                margin: $spacer--semi-medium 0 0;
            }
    }

    ~ .catalog-list__banner {
        .banner__heading {
            .heading {
                font-size: $room__banner-heading-font-size;

                @include mq($screen-m) {
                    font-size: $room__banner-heading-font-size\@medium;
                }

                @include mq($screen-l) {
                    font-size: $room__banner-heading-font-size\@large;
                }
            }
        }
    }
}
