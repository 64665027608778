.locator-result {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: $results-max-height;
    margin: $results-margin;

    @include mq($screen-m) {
        margin: 0;
    }

    &__item {
        cursor: pointer;
        padding: $result-item-padding;
        max-width: $result-item-max-width;
        margin: auto;
        flex-direction: column;

        @include mq($screen-xl) {
            max-width: none;
        }

        &:hover,
        &:focus-visible,
        .focus-visible {
            background-color: $gray;
        }

        &.amlocator-store-desc.-active {
            filter: invert(1);
            background-color: $result-active-background-color;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__name {
        color: $black;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer;
    }

    &__info {
        margin-bottom: $spacer;
        color: $black;
    }

    &__distance {
        color: $color-primary;
        font-weight: $font-weight-bold;
        margin-bottom: $spacer;
    }

    &__online {
        background-color: $color-primary;
        color: $white;
        text-transform: uppercase;
        letter-spacing: $result-online-letter-spacing;
        border-radius: $result-online-border-radius;
        padding: $result-online-padding;
        width: $result-online-width;
        font-size: $font-size-small;
        text-align: center;
    }

    &__retailer {
        display: inline-block;

        &-svg {
            fill: $white;
            border: $result-retailer-svg-border;
            background-color: transparent;
            opacity: 1;
            border-radius: $result-retailer-svg-border-radius;
            margin: 0 $spacer--small;

            &--checked {
                background-color: $black;
            }
        }

        &-label {
            color: $black;
            padding: $result-retailer-label-padding;

            &:hover,
            &:focus-visible,
            .focus-visible {
                color: $color-primary;
            }
        }
    }
}

.today_schedule {
    display: none;
}