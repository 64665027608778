.tab {
    margin: 30px;
    @include mq($screen-m) {
        justify-content: flex-start;
    }
    &__title {
        margin-bottom: $spacer--extra-small;
        @include mq($screen-l) {
            border-bottom: $tab__border;
            margin-bottom: 0;
        }
        &--active {
            margin-bottom: 0;
            background: $white;
            font-weight: 500;
            border-top: $tab__border;
            border-left: $tab__border;
            border-right: $tab__border;
            border-bottom: none;

        }
    }
    &__content {
        background: $white;
        color: $color-secondary;
        padding: $tab__content-padding;
        @include mq($screen-m) {
            padding: $tab__content-padding\@medium;
        }
        @include mq($screen-l) {
            padding: $tab__content-padding\@large;
        }

        &--active {
            border-left: $tab__border;
            border-right: $tab__border;
            border-bottom: $tab__border;
            margin-bottom: $spacer--extra-small;
            &:not(:nth-last-child(1)) {
                border-left: $tab__border;
                border-right: $tab__border;
                border-bottom: $tab__border;
                margin-bottom: $spacer--extra-small;
            }
        }

        .product.attribute.description {
            font-size: $font-size-medium;
            color: $color-secondary;
        }
    }

    .table {
        &--block {
            border: none;
            display: block;
            color: $color-secondary;

            tr, td {
                display: block;
                border: none;
            }

            td.data:before {
                content: none;
            }

            th[scope="row"] {
                width: 100%;
                padding: 0;
                border: none;
                font-weight: $font-weight-bold;
            }

            th[scope="row"] + td {
                padding: $spacer--medium 0;
            }
        }
    }
}
