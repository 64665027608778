$cookie-message__background-color       : $color-primary;
$cookie-message__padding                : $spacer--medium $spacer--extra-large $spacer;
$cookie-message__padding\@medium        : $spacer $spacer--super-large;
$cookie-message__padding\@large         : $spacer 104px;

$cookie-message__text-color             : $gray-lighter;
$cookie-message__text-color-active      : $color-primary;
$cookie-message__text-padding           : 0 0 $spacer 0;
$cookie-message__text-padding\@medium   : 0 106px 0 0;
$cookie-message__text-margin            : 0;
$cookie-message__text-letter-spacing    : normal;
$cookie-message__text-line-height       : 16px;
$cookie-message__text-font-size\@medium : $font-size-base;

$cookie-message__button-color           : $gray-lighter;
$cookie-message__button-padding         : $spacer--small;
$cookie-message__button-text-margin     : 0 $spacer--small 0 0;
$cookie-message__button-min-width       : 100px;
$cookie-message__button-before-bg       : transparent !important; // sass-lint:disable-line no-important

$cookie-message__button-icon-fill       : $gray-lighter;
$cookie-message__button-icon-margin     : $spacer--small;

$cookie-message__link-color             : $gray-lighter;
