.breadcrumbs {
    display: none;

    @include mq($screen-l) {
        display: block;
        margin-bottom: $spacer--extra-large;
    }

    @include mq($screen-xl) {
        display: block;
        margin-bottom: $spacer--semi-large;
    }

    @include mq($screen-xl) {
        display: block;
    }

    &__item:last-child:not(:first-child) {
        color: $font-color-base;

        .breadcrumbs__link {
            color: $font-color-base;
        }
    }
}
