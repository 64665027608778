#product-builder {
    .cpb-product-actions-share {
        display: none;
    }
}
.product.info.detailed {
    .additional-attributes {
        > tbody {
            > tr {
                > th {
                    display: none;
                }
            }
        }
    }
}
.product-view {
    &__details {
        &.loading {
            position: relative;
            .product-view__form {
                opacity: 0;
            }
        }
    }
}
