.am-blog-categories {
    display: flex;
    flex-direction: row;
    list-style-type: none;
    margin: 0 0 $spacer--semi-medium;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    white-space: nowrap;
    max-width: 100%;

    &::-webkit-scrollbar { // sass-lint:disable-line no-vendor-prefixes
        display: none;
    }

    @include mq($screen-l) {
        overflow-x: auto;
        overflow-y: auto;
        flex-wrap: wrap;
        margin: 0;
    }

    &__category {
        &-link {
            color: $nav-category-link-color;
            font-size: $font-size-medium;
            font-weight: $font-weight-bold;
            line-height: $nav-category-link-line-height;
            letter-spacing: $nav-category-link-letter-spacing;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            margin-right: $spacer--large;

            &:hover,
            &:focus,
            &.focus-visible {
                color: $font-color-base;
            }
        }

        &:last-child {
            .amblog-link {
                margin: 0;
            }
        }
    }
}
