.more-info {
    &__trigger {
        .icon.button__icon {
            fill: $more-info__trigger-icon-fill;
        }
    }

    &__label {
        color: $more-info__label-color;
        font-weight: $font-weight-medium;
    }
}
