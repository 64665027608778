.tooltip {
    &__content {
        font-family: $font-family-base;
        font-size: $font-size-small;

        @include mq($screen-l) {
            font-size: $font-size-medium;
        }
    }
}
