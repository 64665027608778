.blog-grid-item {
    margin: $grid-item-margin;
    background-color: $white;
    display: inline-block;

    &__image {
        margin: $grid-item-image-margin;
    }

    &__top {
        display: flex;
        flex-direction: row;
        font-size: $grid-item-top-font-size;
        line-height: $grid-item-top-line-height;
        letter-spacing: $grid-item-top-letter-spacing;
        text-transform: uppercase;
        margin: $grid-item-top-margin;
        align-items: baseline;
        padding: $grid-item-top-padding;

        &-features {
            margin-right: $spacer;

            > * {
                > * {
                    font-family: $font-family-sans-serif;
                    font-weight: $font-weight-bold;
                }
            }
        }

        &-date {
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-bold;
            color: $grid-item-top-date-color;
        }

        @include mq($screen-xl) {
            padding: $grid-item-top-padding\@extra-large;
        }
    }

    &__content {
        padding: $grid-item-content-padding;

        @include mq($screen-xl) {
            padding: $grid-item-content-padding\@extra-large;
        }
    }

    &__title {
        margin: $grid-item-content-title-margin;

        &-link {
            font-size: $grid-item-content-title-font-size;
            line-height: $grid-item-content-link-line-height;
            letter-spacing: $grid-item-content-link-line-letter-spacing;
            color: $font-color-base;
            font-family: $font-family-serif;

            @include mq($screen-m) {
                font-size: $grid-item-content-title-font-size\@medium;
                letter-spacing: $grid-item-content-link-line-letter-spacing\@medium;
            }
        }
    }

    &__description {
        margin: $grid-item-content-description-margin;
        @include blog-post-content-styles;

        > * {
            @include blog-post-content-styles;
        }

        p:last-child {
            margin: 0;
        }
    }

    &__bottom {
        display: flex;
        justify-content: flex-end;

        &-nav {
            font-size: $font-size-base;
            color: $color-primary;
            line-height: $grid-item-bottom-nav-line-height;
            position: relative;
            padding: $grid-item-bottom-nav-padding;
            margin: $grid-item-bottom-nav-margin;

            &-title {
                margin: $grid-item-bottom-nav-title-margin;
                color: $color-primary;
                font-size: $font-size-base;
            }

            svg {
                fill: $color-primary;
            }
        }
    }
}
