
.newsletter {
    align-items: center;
    margin: $newsletter__margin;
    padding: $newsletter__padding;

    @include mq($screen-m) {
        flex-direction: column;
        padding: $newsletter__padding\@medium;
    }

    &__heading {
        text-align: $newsletter__heading-text-align;
        margin: $newsletter__heading-margin;
    }

    &__title {
        text-transform: $newsletter__title-text-transform;

        @include mq($screen-m) {
            font-size: $newsletter__title-font-size\@medium;
            line-height: $newsletter__title-line-height\@medium;
        }
    }

    &__subtitle {
        font-size: $newsletter__subtitle-font-size;
        line-height: $newsletter__subtitle-line-height;
        margin: $newsletter__subtitle-margin;

        @include mq($screen-m) {
            font-size: $newsletter__subtitle-font-size\@medium;
            line-height: $newsletter__subtitle-line-height\@medium;
        }
    }

    &__controls {
        @include mq($screen-m) {
            width: 416px;
            margin: $newsletter__controls-margin\@medium;
        }

        @include mq($screen-xl) {
            width: 592px;
            max-width: unset;
            margin: $newsletter__controls-margin\@extra-large;
        }
    }

    &__field {
        border-color: $newsletter__field-border-color;
        border-top-left-radius: $newsletter__field-border-radius;
        border-bottom-left-radius: $newsletter__field-border-radius;
        font-family: $newsletter__field-font-family;
    }

    &__agreements {
        @include mq($screen-xl) {
            max-width: $newsletter__agreements-max-width\@extra-large;
        }
    }

    .checkbox {
        &__label {
            @include mq($screen-xl) {
                padding: $newsletter__checkbox-label-padding\@extra-large;
            }
        }

        &__text {
            width: 100%;
        }

        .mage-error {
            bottom: unset;
        }
    }
}
