@import 'slick';

// Forcing equal heights in slick
// Fix for 'recently viewed' products slider
.slick-slider {
    &.slick-initialized {
        .slick-track {
            display: flex !important;
            flex-flow: row nowrap;
        }
        .slick-slide {
            display: flex !important;
            height: inherit !important;
        }
    }
}
