.products-map {
    position: relative;

    &__content {
        position: relative;
    }

    &__points {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        &--bedroom {
            .products-map__point {
                &--1 {
                    top: 25%;
                    left: 20%;
                }

                &--2 {
                    top: 45%;
                    left: 67%;
                }

                &--3 {
                    top: 64%;
                    left: 16%;
                }

                &--4 {
                    top: 68%;
                    left: 92%;
                }
            }
        }
    }

    &__point {
        position: absolute;
        border: none;
        width: $spacer--small;
        height: $spacer--small;
        border-radius: 50%;
        margin: -$spacer--small 0 0 -$spacer--small;
        padding: 0;
        background: rgba(251, 251, 251, 0.6);
        min-width: $spacer--small;
        min-height: $spacer--small;

        &:hover,
        &:focus,
        &:active {
            background: $gray-light;
            border: none;
        }

        &:focus {
            border: 1px solid $gray-dark;
        }

        &::after {
            content: none;
        }

        &:hover:before {
            transform: none;
        }

        &::before {
            content: "";
            position: relative;
            border-radius: 50%;
            border: 1px solid white;
            background: #0075e4;
            width: $spacer--extra-small;
            height: $spacer--extra-small;
            outline: #0075e433 solid 2px;
            transform: none;
            transform-origin: unset;
        }

        @include mq($screen-m) {
            width: $spacer--semi-medium;
            height: $spacer--semi-medium;

            &::before {
                width: $spacer;
                height: $spacer;
                outline-width: $spacer--extra-small;
            }
        }

        @include mq($screen-l) {
            &::before {
                animation: pulse 2s infinite;
            }
        }
    }
}

@keyframes pulse {
    0% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 #0075e433;
    }

    70% {
        transform: scale(1);
        box-shadow: 0 0 0 10px rgba(126, 127, 115, 0);
    }

    100% {
        transform: scale(0.95);
        box-shadow: 0 0 0 0 rgba(126, 127, 115, 0);
    }
}
