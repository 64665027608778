.product-view {
    background-color: $product-view__bg;
    margin-top: $product-view__margin-top;
    margin-bottom: $product-view__margin-bottom;

    @include mq($screen-m) {
        margin-top: $product-view__margin-top\@medium;
        margin-bottom: $product-view__margin-bottom\@medium;
    }

    @include mq($screen-l) {
        padding: $product-view__padding\@large;
        margin-top: $product-view__margin-top\@large;
    }

    @include mq($screen-xl) {
        margin-top: $product-view__margin-top\@xl;
    }

    &__title {
        font-weight: $product-view__title-font-weight;
        line-height: $product-view__title-line-height;

        @include mq($screen-m) {
            line-height: $product-view__title-line-height\@medium;
        }

        @include mq($screen-xl) {
            margin: $product-view__title-margin\@xl;
            font-size: $product-view__title-font-size\@xl;
        }
    }

    &__rating {
        margin: $product-view__rating-margin;
        align-items: center;

        @include mq($screen-m) {
            margin: $product-view__rating-margin\@medium;
        }
    }

    &__short-description {
        &> p {
            color: $product-view__short-description-color;
            font-size: $product-view__short-description-font-size;
            line-height: $product-view__short-description-line-height;
        }
    }

    &__main-actions {
        @include mq($screen-l) {
            display: flex;
            flex-wrap: wrap;
            align-items: flex-end;
            justify-content: space-between;
        }
    }

    &__price {
        margin: $product-view__price-margin;
        font-family: $font-family-sans-serif;

        @include mq($screen-l) {
            width: 100%;
        }

        .price-container {
            display: block;
            height: 39px;
        }

        .price__value {
            font-size: $product-view__price-value-font-size;
            font-weight: $product-view__price-value-font-weight;
            line-height: $product-view__price-value-line-height;
            &:not(.price__value--special) {
                color: $product-view__price-value-color;
            }

            &--old {
                font-size: $product-view__price-value-font-size--old;
            }

            @include mq($screen-m) {
                font-size: $product-view__price-value-font-size;

                &--old {
                    font-size: $product-view__price-value-font-size--old\@medium;
                }
            }
        }
    }

    &__qty {
        @include mq($screen-l) {
            margin: $product-view__qty-margin\@large;
        }
    }

    &__form {
        margin: $product-view__form-margin;
    }

    &__buttons-wrapper {
        @include mq($screen-l) {
            flex: $product-view__buttons-wrapper-flex\@large;
        }
    }

    &__buttons {
        padding-bottom: $product-view__buttons-padding-bottom;

        @include mq($screen-l) {
            padding-bottom: $product-view__buttons-padding-bottom\@large;
            width: $product-view__buttons-width\@large;
        }
    }

    // action buttons
    &__addtowishlist {
        display: $product-view__button-wishlist-display;
    }

    &__contact-us {
        margin: $product-view__button-contact-margin;

        @include mq($screen-m) {
            margin: $product-view__button-contact-margin\@medium;
        }
    }

    &__store-locator {
        min-height: $product-view__button-min-height;
        flex: 1;
    }

    &__error {
        margin-top: $product-view__error-margin;
        font-family: $product-view__error-font-family;
        color: $product-view__error-color;
    }

    &__additional-actions {
        @include mq($screen-m) {
            display: flex;
        }
    }

    &__contact-us,
    &__spec-sheet {
        min-height: $product-view__button-min-height;
        width: $product-view__button-additional-width;

        @include mq($screen-m) {
            width: $product-view__button-additional-width\@medium;
            flex: 1;
        }
    }

    &__made-to-order {
        font-family: $product-view__made-to-order-font-family;
        font-size: $product-view__made-to-order-font-size;
        color: $product-view__made-to-order-color;
        line-height: $product-view__made-to-order-line-height;
        border:  $product-view__made-to-order-border;
        text-decoration: none;
        cursor: pointer;
    }

    &__add-to-cart,
    &__update-cart {
        .button__text {
            display: block;
        }
    }
}

// specsheet page
.specsheet {
    .product-view {
        &__details {
            margin-bottom: $spacer--medium;
            @include mq($screen-m) {
                float: left;
                width: 50%;
            }
        }

        &__info-detailed {
            clear: both;

            &> p {
                color: $product-view__short-description-color;
                font-size: $product-view__short-description-font-size;
                line-height: $product-view__short-description-line-height;
            }
        }

        &__media {
            margin-bottom: $spacer--semi-medium;
            background: $white;
            text-align: center;

            @include mq($screen-m) {
                float: left;
                width: calc(50% - #{$spacer--semi-medium});
                margin-right: $spacer--semi-medium;
            }

            img {
                display: inline-block;
                max-height: 480px;
            }
        }
    }
}
