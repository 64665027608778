.swatch {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    &__title {
        flex: 1;
    }

    &__sample-link {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        line-height: 48px;
    }

    &__option-container {
        &.disabled {
            display: none;
        }
    }

    &__option {
        pointer-events: none;
    }

    &--max-height {
        max-height: 164px;
        overflow: hidden;
    }

    &__container {
        .finish_type,
        .hardware {
            .swatch__option-container {
                border-width: $swatch__finish-type-border-width;
            }
        }

        .bed_type,
        .bed_size,
        .option {
            .swatch__option-container {
                padding: $swatch__with-text-padding;
            }
        }
    }
}

.swatch-option-tooltip {
    max-width: $swatch-tooltip__max-width;
    min-height: 20px;
    position: absolute;
    background: $swatch-tooltip__bg-color;
    color: $swatch-tooltip__text-color;
    display: none;
    z-index: $z-index-highest;
    text-align: center;
    border-radius: 5px;
    padding: $spacer--extra-small $spacer;

    &__corner {
        left: 40%;
        position: absolute;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: $swatch-tooltip__corner-size $swatch-tooltip__corner-size 0 $swatch-tooltip__corner-size;
        border-color: $swatch-tooltip__bg-color transparent transparent transparent;
        top: 100%;
    }

    .qtip-content .title,
    &__title {
        max-width: $swatch-tooltip__max-width;
        color: $swatch-tooltip__text-color;
        text-align: center;
        display: block;
        overflow: hidden;
        font-size: 13px;
    }

    &__image {
        margin: 7px auto 0;
        display: block;
        overflow: hidden;
        width: 120px;
        height: 100px;
    }
}

.table-builder {
    .swatch-attribute {
        .mageworx-swatch-container > .mageworx-swatch-option {
            outline: none;
            box-sizing: border-box;
            border: $swatch__option-border;
            border-width: 2px;
            margin: $swatch__option-margin;
            padding: 2px;
            transition: $swatch__transition;
            cursor: pointer;

            &:hover,
            &:focus,
            &.selected {
                outline: none;
                border-color: $swatch__option-border-color--active;
                border-width: 2px;
            }

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }

    .field #value {
        display: none;
    }
}
