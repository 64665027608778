/*  Blog Adjustments */
.am-blog-post {
    &__title {
        font-size: clamp(2.5rem, 5vw, 3.5rem);
        line-height: 1.05;
        margin: 5px 0 7.5px;
    }
    &__content {
        h2 {
            font-size: clamp(2rem, 3vw, 2.5rem);
            line-height: 1.1;
            margin: 0 0 5px;
        }
        p {
            font-size: clamp(.95rem, 3vw, 1.1rem) !important;
            line-height: 1.4;
            margin: 0 0 7.5px;
        }
        div[data-content-type=text] {
            margin: 0 0 20px;
        }
        blockquote {
            font-style: normal;
        }
    }
}
@media screen and (max-width: 600px) {
    .am-blog-post__content {
        blockquote {
            float: none !important;
            margin: 30px 0 !important;
            padding: 0 !important;
        }
    }
}
