.product-grid-item {
    background-color: $product-grid-item__bg-color;

    &__link {
        margin: $product-grid-item__link-margin;
    }

    &__image-wrapper {
        padding: $product-grid-item__image-wrapper-padding;

        .lazyload,
        .lazyloaded,
        .lazyloading {
            max-width: 100%;
            max-height: 100%;
        }

        .image {
            object-fit: contain;
        }

    }

    &__name {
        @include mq($screen-m) {
            margin: $product-grid-item__name-margin\@medium;
        }

        @include mq($screen-xl) {
            font-size: $product-grid-item__name-font-size\@extra-large;
        }
    }

    &__name-link {
        font-family: $font-family-sans-serif;
        @include mq($screen-m) {
            line-height: $product-grid-item__name-line-height\@medium;
        }
    }

    &__rating {
        display: none;
    }

    &__details {
        background: $product-grid-item__bg-color;
        color: $color-secondary;

        @include mq($screen-m) {
            padding: $product-grid-item__details-padding\@medium;
            margin: $product-grid-item__details-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $product-grid-item__details-margin\@large;
        }

        @include mq($screen-xl) {
            margin: $product-grid-item__details-margin\@extra-large;
        }
    }

    &__price {
        justify-content: flex-start;
        .price__value {
            line-height: $font-line-height-small;
            color: $gray-dark;
            font-weight: $font-weight-base;
            font-size: $font-size-base;
        }
        .price__value--special {
            font-weight: $font-weight-bold;
        }
    }
}

#crossed-price {
    >.crossed {
        max-width: 105px;
    }
}

ul.sku-links {
    display: block !important;
    margin-left: 0 !important;
}

a.action.advanced {
    display: none;
}
