.products-map-item {
    display: none;
    width: 100%;
    background-color: $white;

    @include mq($screen-m) {
        position: absolute;
        top: 0;
        left: 0;
        width: 300px;
        box-shadow: 0 2px 4px 0 rgba(26, 27, 29, 0.2);

        &--arrow-right,
        &--arrow-left {
            &:after {
                content: '';
                position: absolute;
                right: -10px;
                top: 50%;
                transform: translateY(-50%);
                border-top: 10px solid transparent;
                border-bottom: 10px solid transparent;
                border-left: 10px solid $white;
            }
        }

        &--arrow-left {
            &:after {
                left: -20px;
                right: auto;
                border-right: 10px solid $white;
                border-left-color: transparent;
            }
        }
    }

    &--active {
        display: block;
    }

    &__link {
        display: flex;
        justify-content: center;
        padding: $spacer--medium $spacer--medium $spacer;

        @include mq($screen-m) {
            flex-wrap: wrap;
            justify-content: flex-start;
            padding: $spacer--semi-medium $spacer--semi-medium $spacer;
        }

        &:hover,
        &:focus {
            text-decoration: none;
        }
    }

    &__name {
        font-family: $font-family-base;
        font-size: $font-size-large;
        line-height: 24px;
        font-weight: $font-weight-normal;
        margin: 0;
    }

    &__description {
        color: $gray-dark;
        font-size: $font-size-base;
        line-height: 16px;
        margin: $spacer--medium 0;
    }
}
