.am-blog-search {
    min-width: $sidebar-search-min-width;
    margin-left: $spacer;

    &__container {
        display: flex;
        flex-direction: row;
        position: relative;
        justify-content: flex-end;

        .input-box {
            flex-grow: $sidebar-form-input-flex-grow;
        }
    }

    &__form {
        &-input {
            background-color: transparent;
            border: $sidebar-search-input-border;
            text-align: end;
            color: $gray-dark;
            letter-spacing: $sidebar-search-input-letter-spacing;
            font-size: $font-size-base;
            line-height: $sidebar-search-input-line-height;
            min-width: $sidebar-search-min-width;
            padding-left: $sidebar-search-padding-left;
        }

        &-button {
            .icon {
                width: $sidebar-search-svg-width;
                fill: $gray-dark;
            }

            &::before,
            &::after {
                content: none;
            }

            &:hover {
                .icon {
                    fill: $gray-dark;
                }
            }
        }
    }
}
