.cookie-message {
    @include mq($screen-m) {
        padding: $cookie-message__padding\@medium;
    }

    @include mq($screen-l) {
        padding: $cookie-message__padding\@large;
    }

    &__container {
        justify-content: unset;
        flex-direction: column;
        align-items: flex-start;

        @include mq($screen-m) {
            align-items: center;
            flex-direction: row;
        }
    }

    &__text {
        text-align: left;
        line-height: $cookie-message__text-line-height;

        > a {
            color: $cookie-message__link-color;
        }

        @include mq($screen-m) {
            padding: $cookie-message__text-padding\@medium;
            font-size: $cookie-message__text-font-size\@medium;
        }
    }

    &__button {
        min-width: $cookie-message__button-min-width;
        display: flex;
        justify-content: flex-start;
        height: 100%;

        &:before {
            background-color: $cookie-message__button-before-bg;
        }

        .button__text {
            display: inline-block;
            margin: $cookie-message__button-text-margin;
        }

        &-icon {
            margin: $cookie-message__button-icon-margin;
            fill: $gray-light !important; // sass-lint:disable-line no-important
        }
    }

    &--actions {
        .cookie-message__button {
            padding: 0;
        }
    }
}

.cookie-message-wrapper {
    z-index: $z-index-highest;
    position: sticky;
    bottom: 0;
}
