.am-blog-post {
    background-color: $white;
    padding: $post-main-padding;

    &__top {
        display: flex;
        flex-direction: row;
        font-size: $post-top-font-size;
        line-height: $post-top-line-height;
        letter-spacing: $post-top-letter-spacing;
        text-transform: uppercase;
        margin: $post-top-margin;
        align-items: baseline;
        padding: $post-base-padding;

        &-date {
            color: $post-top-date-color;
        }

        @include mq($screen-m) {
            padding: $post-base-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $post-base-padding\@large;
        }

        @include mq($screen-xxl) {
            padding: $post-base-padding\@extra-large;
        }

        &-features {
            margin-right: $spacer;

            > * {
                > * {
                    font-family: $font-family-sans-serif;
                    font-weight: $font-weight-bold;
                }
            }
        }
    }

    &__title {
        font-size: $post-title-font-size;
        line-height: $post-title-line-height;
        letter-spacing: $post-title-letter-spacing;
        color: $font-color-base;
        font-family: $font-family-serif;
        margin: $post-title-margin;
        padding: $post-base-padding;

        @include mq($screen-m) {
            padding: $post-base-padding\@medium;
            font-size: $post-title-font-size\@medium;
            line-height: $post-title-line-height\@medium;
            letter-spacing: $post-title-letter-spacing\@medium;
            margin: $post-title-margin\@medium;
        }

        @include mq($screen-l) {
            padding: $post-base-padding\@large;
        }

        @include mq($screen-xxl) {
            padding: $post-base-padding\@extra-large;
        }
    }

    &__image {
        margin: $post-image-margin;
        display: flex;
        justify-content: center;

        @include mq($screen-m) {
            padding: $post-image-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $post-image-padding\@large;
        }

        @include mq($screen-xxl) {
            padding: $post-image-padding\@extra-large;
        }
    }

    &__content {
        padding: $post-base-padding;

        @include mq($screen-m) {
            padding: $post-base-padding\@medium;
        }

        @include mq($screen-l) {
            padding: $post-base-padding\@large;
        }

        @include mq($screen-xxl) {
            padding: $post-base-padding\@extra-large;
        }

        &-text {
            @include blog-post-content-styles;
            margin: $post-content-text-margin;

            > * {
                @include blog-post-content-styles;

                > * {
                    @include blog-post-content-styles;
                }
            }
        }
    }

    &__tags {
        margin: $post-tags-margin;

        &-separator {
            margin: $post-tags-separator-margin;
            color: $gray;
            pointer-events: none;
        }

        &-separator:last-child {
            display: none;
        }

        &-tag {
            color: $color-primary;
            text-transform: uppercase;
            font-size: $font-size-small;
            line-height: $post-tags-line-height;
            font-family: $font-family-sans-serif;
            font-weight: $font-weight-bold;
        }
    }
}
