.categories-list {
    &__link {
        margin: $categories-list__link-margin;
        line-height: $categories-list__line-height;
        letter-spacing: $categories-list__letter-spacing;

        @include mq($screen-xl) {
            font-size: $categories-list__font-size\@extra-large;
        }
    }
}
