.teaser {
    &--teriary {
        flex-direction: row-reverse;
        background-color: $pale-cyan;

        .teaser {
            &__image {
                @include mq($screen-l) {
                    width: 90%;
                }
            }
            &__content {
                padding: 0;

                &-block {
                    position: $teaser-teriary-content-block-position;
                    align-items: $teaser-teriary-content-block-align;
                    background-color: $teaser-teriary-content-block-background-color;
                    padding: $teaser-teriary-content-block-margin;

                    @include mq($screen-m) {
                        padding: $teaser-teriary-content-block-margin-m;
                    }

                    .content-block {
                        padding: 0;

                        &__heading {
                            font-size: $font-size-super-large;
                            line-height: $teaser-teriary-heading-line-height;
                            letter-spacing: $teaser-teriary-heading-line-spacing;
                            color: $teaser-teriary-heading-font-color;
                            padding: $teaser-teriary-heading-padding;
                            margin: $teaser-teriary-heading-margin;

                            @include mq($screen-m) {
                                font-size: $font-size-super-extra-large;
                            }
                        }

                        &__description {
                            color: $font-color-base;
                            font-size: $teaser-teriary-description-font-size;
                            line-height: $teaser-teriary-description-line-height;
                            margin: $teaser-teriary-description-margin;
                        }

                        &__link {
                            padding: $teaser-teriary-link-padding;

                            .icon {
                                fill: $teaser-teriary-link-icon-fill;
                                margin: $teaser-teriary-link-icon-margin;
                            }
                        }
                    }
                }
            }
        }
    }
}
