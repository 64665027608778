$catalog-list__margin                         : $spacer--semi-medium 0 0;
$catalog-list__margin\@medium                 : $spacer--semi-large 0 0;
$catalog-list__margin\@large                  : $spacer--super-large 0 0;
$catalog-list__margin\@extra-large            : $spacer--super-extra-large 0 0;
$catalog-list__font-family                    : $font-family-sans-serif;

$catalog-list__banner-padding                 : 0;
$catalog-list__banner-padding\@medium         : 0;
$catalog-list__banner-padding\@large          : 0;
$catalog-list__banner-border                  : none;
$catalog-list__banner-margin                  : $spacer--extra-large 0 $spacer--large;

$catalog-list__sidebar-width                  : 0;
$catalog-list__sidebar-width\@xl              : 0;
$catalog-list__sidebar-margin\@xl             : 0 $spacer--large 0 0;
