.store-locator {
    background-color: $white;
    padding: $store-locator-padding;

    @include mq($screen-m) {
        padding: $store-locator-padding\@medium;
    }

    @include mq($screen-l) {
        padding: $store-locator-padding\@large;
    }

    @include mq($screen-xl) {
        padding: $store-locator-padding\@extra-large;
    }

    &__heading {
        text-align: center;
        margin: $locator-heading-margin;
        font-size: $locator-heading-font-size;
        line-height: $spacer--extra-large;
        letter-spacing: $locator-heading-letter-spacing;
        color: $font-color-base;
        font-family: $font-family-serif;

        @include mq($screen-m) {
            font-size: $locator-heading-font-size\@medium;
            letter-spacing: $locator-heading-letter-spacing\@medium;
        }

        @include mq($screen-xl) {
            font-size: $locator-heading-font-size\@extra-large;
        }
    }

    &__wrapper {
        @include mq($screen-m) {
            display: flex;
            justify-content: center;
        }
    }

    &__col {
        @include mq($screen-m) {
            display: flex;
            justify-content: center;
            width: 100%;
            align-items: flex-start;

            &--left {
                width: $locator-col-left-width;
                margin-right: $spacer--semi-medium;
            }

            &--right {
                width: $locator-col-right-width;
            }
        }
    }

    &__map {
        width: 100%;
        min-height: $locator-map-min-height;
        height: 100%;

        @include mq($screen-m) {
            min-height: $locator-map-min-height\@medium;
        }

        @include mq($screen-xl) {
            min-height: $locator-map-min-height\@extra-large;
        }
    }

    &__breadcrumbs {
        margin-bottom: $spacer;
    }
}
