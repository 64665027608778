// sass-lint:disable no-important
// !important necessary to overwrite photoswipe base styles
$pswp__video-wrapper-max-with        : 900px;
$pswp__video-wrapper-margin          : 0 auto;
$pswp__video-container-padding-bottom: 56.25%;
$pswp__video-container-padding-top   : 25px;
$pswp__button-zoom-bg-position       : -132px 0 !important;
$pswp__button-close-bg-position      : 0 -44px !important;
$pswp__button-share-bg-position      : -44px -44px !important;
$pswp__tooltip-padding               : $spacer--medium !important;

.pswp {
    &__zoom-wrap {
        text-align: center;

        &:before {
            content: '';
            display: inline-block;
            height: 100%;
            vertical-align: middle;
        }
    }

    &__video-wrapper {
        line-height: 0;
        width: 100%;
        max-width: $pswp__video-wrapper-max-with;
        position: relative;
        display: inline-block;
        vertical-align: middle;
        margin: $pswp__video-wrapper-margin;
        text-align: left;
        z-index: $z-index-high;
    }

    &__video-container {
        position: relative;
        padding-bottom: $pswp__video-container-padding-bottom;
        padding-top: $pswp__video-container-padding-top;
        height: 0;
        width: 100%;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }

        video {
            width: 100% !important;
            height: auto !important;
        }
    }

    &__button {
        &:hover,
        &:focus {
            background-color: transparent;
            border: none;
        }
    }

    &__button {
        &--zoom:active,
        &--zoom:hover {
            background-position: $pswp__button-zoom-bg-position;
        }
        &--close:active,
        &--close:hover {
            background-position: $pswp__button-close-bg-position;
        }
        &--share,
        &--share {
            background-position: $pswp__button-share-bg-position;
            &:hover,
            &:active {
                background-position: $pswp__button-share-bg-position;
            }
        }
    }

    &__share-tooltip {
        a {
            padding: $pswp__tooltip-padding;
        }
    }

    &__button {
        &--zoom {
            display: block !important;
        }
    }

    &__caption {
        &__center {
            max-width: none !important;
            text-align: center !important;

        }
    }
}
