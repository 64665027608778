$newsletter__padding                                : $spacer--large * 2 0 72px;
$newsletter__padding\@medium                        : $spacer--semi-large 0 80px;

$newsletter__heading-padding\@extra-large           : 0;
$newsletter__heading-margin                         : 0 0 $spacer--large 0;
$newsletter__heading-margin\@medium                 : 0 $spacer--semi-medium 0;
$newsletter__heading-text-align                     : center;

$newsletter__title-margin                           : 0 0 $spacer;
$newsletter__title-font-family                      : $font-family-serif;
$newsletter__title-font-size                        : $font-size-extra-large;
$newsletter__title-font-size\@medium                : 32px;
$newsletter__title-font-weight                      : $font-weight-normal;
$newsletter__title-line-height                      : 32px;
$newsletter__title-line-height\@medium              : 48px;
$newsletter__title-color                            : $gray-darker;
$newsletter__title-text-transform                   : capitalize;

$newsletter__subtitle-font-size                     : $font-size-base;
$newsletter__subtitle-font-size\@medium             : $font-size-medium;
$newsletter__subtitle-line-height                   : 16px;
$newsletter__subtitle-line-height\@medium           : 24px;
$newsletter__subtitle-margin                        : 0;

$newsletter__controls-margin\@extra-large           : 0 0 $spacer--semi-medium;

$newsletter__button-min-width                       : 112px;

$newsletter__checkbox-label-padding                 : 0 0 0 44px;
$newsletter__checkbox-label-padding\@extra-large    : 0 0 0 36px;

$newsletter__checkbox-before-background             : $gray-darkest;

$newsletter__agreements-margin                      : 0 0 0 $spacer--small;
$newsletter__agreements-max-width                   : 400px;
$newsletter__agreements-max-width\@extra-large      : 580px;

$newsletter__field-border-radius                    : 4px;
$newsletter__field-border-color                     : #a6a5a6;
$newsletter__field-font-family                      : $font-family-base;
