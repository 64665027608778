$filters__heading-font-family           : $font-family-serif;
$filters__heading-font-size\@medium     : $font-size-super-large;
$filters__heading-font-weight           : $font-weight-normal;
$filters__heading-color                 : $gray-darker;
$filters__heading-padding\@large        : 0 $spacer 0 0;

$filters__trigger-padding               : 0 $spacer--medium 0 0;
$filters__trigger-padding\@medium       : $spacer $spacer--medium $spacer 0;

$filters__trigger-text-transform        : capitalize;
$filters__trigger-text-color            : $gray-darker;
$filters__trigger-line-height           : 48px;
$filters__trigger-border                : $border-base;
$filters__trigger-border-width          : 0 0 1px 0;
$filters__trigger-text-margin           : 0;

$filters__modal-top-padding\@large      : $spacer 0 $spacer--semi-medium 0;
$filters__modal-middle-padding          : $spacer--semi-medium 0;
$filters__modal-bg                      : transparent;

$filters__dropdown-list-label-padding   : 0 $spacer--extra-large 0 0;
$filters__dropdown-list-label-margin    : 0 0 $spacer--medium 0;
$filters__dropdown-bg                   : transparent;
