.am-blog {
    &#maincontent { // sass-lint:disable-line no-ids
        margin: $blog-layout-margin;

        @include mq($screen-m) {
            margin: $blog-layout-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $blog-layout-margin\@large;
        }

        @include mq($blog-layout-shift-width) {
            margin: $blog-layout-margin\@extra-large;
        }
    }

    &__grid {
        list-style-type: none;
        padding: 0;
        margin: 0 0 $spacer--super-extra-large;
    }

    &__grid--short {
        @include mq($screen-m) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: $grid-container-columns-gap; // sass-lint:disable-line no-misspelled-properties
        }

        @include mq($screen-l) {
            grid-template-columns: repeat(3, 1fr);
        }
    }

    &__grid--normal {
        @include mq($screen-m) {
            columns: $grid-container-columns-display\@medium;
            column-gap: $grid-container-columns-gap;
        }

        @include mq($screen-l) {
            columns: $grid-container-columns-display\@large;
        }
    }

    &__container {
        display: flex;
        flex-direction: column-reverse;

        @include mq($screen-m) {
            flex-direction: column;
        }

        .main,
        .sidebar-main {
            max-width: none;
        }
    }

    &__heading {
        color: $font-color-base;
        text-align: center;
        font-size: $blog-heading-font-size;
        font-family: $font-family-serif;
        letter-spacing: $blog-heading-letter-spacing;

        @include mq($screen-m) {
            font-size: $blog-heading-font-size\@medium;
            letter-spacing: $blog-heading-letter-spacing\@medium;
        }

        @include mq($screen-xl) {
            font-size: $blog-heading-font-size\@extra-large;
            letter-spacing: $blog-heading-letter-spacing\@extra-large;
        }
    }
}
