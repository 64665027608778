.review {
    &__amount {
        font-family: $font-family-sans-serif;
    }

    &__summary-add {
        display: none;
    }

    &__fieldset {
        @include mq($screen-l) {
            margin: 0;
        }
    }
}
