.filters {
    &__modal {
        @include mq($screen-l) {
            background-color: $filters__modal-bg;
        }
    }

    &__container {
        overflow: auto;

        @include mq($screen-l) {
            overflow: unset;
        }

        .modal &.modal__container {
            @include mq($screen-l) {
                background-color: $filters__modal-bg;
                padding: $filters__container-padding\@large;
            }
        }
    }

    &__content-middle {
        overflow: visible;
    }

    &__heading,
    &__trigger {
        line-height: $filters__trigger-line-height;
        color: $filters__heading-color
    }

    &__trigger {
        .button-text {
            margin: $filters__trigger-text-margin;
        }
    }

    &__item {
        &:first-of-type {
            border-top: $filters__trigger-border;

            @include mq($screen-l) {
                border-top: none;
            }
        }
    }

    &__dropdown {
        background-color: $filters__dropdown-bg;
        .dropdown-list__label {
            background-color: $filters__dropdown-bg;
            padding: $filters__dropdown-list-label-padding;
            margin: $filters__dropdown-list-label-margin;
        }
    }
}
