.toolbar {
    margin: $toolbar__margin;

    @include mq($screen-m) {
        margin: $toolbar__margin\@medium;
    }

    @include mq($screen-l) {
        margin: $toolbar__margin\@large;
    }

    &__element {
        width: 100%;

        @include mq($screen-m) {
            display: flex;
            flex: 1;

            &--right {
                justify-content: flex-end;
            }
        }
    }

    &__limiter {
        margin: $toolbar__limiter-margin;
        text-align: $toolbar__label-align;
        width: fit-content;
    }

    &__sorter-label,
    &__limiter-label  {
        min-width: $toolbar__label-min-width;
    }

    &__sorter-select,
    &__limiter-select {
        background-color: $toolbar__dropdown-bg;
        font-family: $font-family-base;
        color: $gray-darker;
    }

    &__sorter-select {
        min-width: $toolbar__sorter-select-min-width;
        width: 100%;
    }

    .select2-container {
        .select2-selection--single {
            background-color: $toolbar__dropdown-bg;
            font-family: $font-family-base;
        }
    }
}
