.am-blog-sidebar {
    max-width: none;
    margin: 0 0 $spacer--semi-medium;

    display: flex;
    flex-direction: column-reverse;

    @include mq($screen-l) {
        flex-direction: row-reverse;
        justify-content: space-between;
    }
}
