$banner-hero-margin                                        : $spacer--semi-medium $spacer 0;

$banner-category-letter-spacing\@small                     : -0.38px;
$banner-category-letter-spacing\@medium                    : -0.5px;
$banner-category-line-height                               : $spacer--extra-large;
$banner-category-margin                                    : 0 0 $spacer--semi-medium;
$banner-category-text-offset\@small                        : $spacer--extra-small 0 0;
$banner-category-text-offset\@medium                       : $spacer 0 0;
$banner-category-border-bottom                             : none;
$banner-category-padding                                   : 0;
$banner-category-background-color                          : transparent;
$banner-category-after-content                             : none;

$banner__heading-margin--category                          : 0;

$banner__heading-wrapper-position--category-image\@medium  : static;
$banner__heading-wrapper-background--category-image\@medium: none;
$banner__heading-wrapper-max-width--category-image\@medium : auto;
