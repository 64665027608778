.banner {
    &--hero {
        margin: $banner-hero-margin;

        &:hover,
        &.focus-visible {
            @include banner-hover;
        }
    }

    &--category {
        margin: $banner-category-margin;

        &:hover,
        &.focus-visible {
            @include banner-hover;
        }

        .banner__content {
            border-bottom: $banner-category-border-bottom;
            padding: $banner-category-padding;
            background-color: $banner-category-background-color;

            &::after {
                content: $banner-category-after-content;
            }
        }

        .banner__text {
            font-size: $font-size-large;
            line-height: $banner-category-line-height;
            font-family: $font-family-serif;
            color: $font-color-base;
            text-transform: none;
            letter-spacing: $banner-category-letter-spacing\@small;
            padding: $banner-category-text-offset\@small;

            @include mq($screen-m) {
                font-size: $font-size-extra-large;
                letter-spacing: $banner-category-letter-spacing\@medium;
                padding: $banner-category-text-offset\@medium;
            }
        }
    }

    &--additional {
        max-width: 100%;

        &:hover,
        &.focus-visible {
            @include banner-hover;
        }
    }

    &--category-image {
        .banner__heading {
            .heading {
                // Overwrite WebForms text-center class
                text-align: center !important;  // sass-lint:disable-line no-important
            }
        }
        .banner__button {
            display: none;
        }
    }
}
