// STOTRELOCATOR LAYOUT
$layout-content-margin-top               : $spacer--medium;
$layout-content-margin-top\@medium       : $spacer--semi-medium;
$layout-content-margin-top\@large        : 0;
$layout-content-margin-bottom            : $spacer--super-large;

// STORELOCATOR MAIN
$store-locator-padding                   : $spacer--large $spacer $spacer;
$store-locator-padding\@medium           : $spacer--semi-medium $spacer--medium $spacer--medium;
$store-locator-padding\@large            : $spacer--large $spacer--medium $spacer--medium;
$store-locator-padding\@extra-large      : $spacer--large;

$locator-heading-margin                  : 0 0 $spacer--extra-large;
$locator-heading-font-size               : $font-size-extra-large;
$locator-heading-font-size\@medium       : 40px;
$locator-heading-font-size\@extra-large  : 48px;
$locator-heading-letter-spacing          : -0.1px;
$locator-heading-letter-spacing\@medium  : -0.17px;

$locator-col-left-width                  : 40%;
$locator-col-right-width                 : 60%;

$locator-map-min-height                  : 215px;
$locator-map-min-height\@medium          : 383px;
$locator-map-min-height\@extra-large     : 583px;

// STORELOCATOR FILTER
$filter-title-font-size                  : $font-size-large;
$filter-title-font-size\@extra-large     : $font-size-extra-large;
$filter-title-line-height                : $spacer--extra-large;
$filter-title-margin                     : 0 0 $spacer--semi-medium;
$filter-title-letter-spacing             : 3px;
$filter-title-letter-spacing\@extra-large: 4px;

$filter-input-margin-bottom              : $spacer--semi-medium;
$filter-input-text-border-radius         : 4px;
$filter-input-text-padding               : 0 $spacer--medium;

$filter-action-button-letter-spacing     : 1px;
$filter-action-button-max-width          : 224px;

$filter-content-max-width                : 224px;
$filter-checkbox-labe-font-size          : $font-size-medium;

// STORELOCATOR RESULT
$results-max-height                      : 486px;
$results-margin                          : 0 0 $spacer--large;

$result-item-padding                     : $spacer--medium;
$result-item-max-width                   : 300px;

$result-online-letter-spacing            : 1px;
$result-online-border-radius             : 2px;
$result-online-padding                   : 1px 10px;
$result-online-width                     : 175px;

$result-retailer-svg-border              : 2px $border-style-base $gray-darkest;
$result-retailer-svg-border-radius       : 2px;
$result-retailer-label-padding           : $spacer--small 0 $spacer--small $spacer--extra-large;

$result-active-background-color          : #ffaf69;
