.room {
    &__items {
        list-style-type: none;
        padding: 0;
        margin: 0;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: $spacer--medium; // sass-lint:disable-line no-misspelled-properties

        @include mq($screen-m) {
            grid-template-columns: repeat(3, 1fr);
        }

        @include mq($screen-l) {
            grid-template-columns: repeat(4, 1fr);
        }
    }

    &__item {
        background-color: $white;
    }

    &__link {
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    &__image {
        margin: $room-image-margin;
        width: 100%;
    }

    &__title {
        font-size: $font-size-medium;
        line-height: $spacer--semi-medium;
        letter-spacing: $room-title-letter-spacing\@small;
        color: $font-color-base;
        font-family: $font-family-serif;
        padding: $room-title-padding\@small;
        margin: 0;

        @include mq($screen-m) {
            padding: $room-title-padding\@medium;
            font-size: $font-size-large;
            line-height: $room-title-line-height;
            letter-spacing: $room-title-letter-spacing\@medium;
        }

        @include mq($screen-xl) {
            font-size: $font-size-extra-large;
            line-height: $room-title-line-height\@extra-large;
            letter-spacing: $room-title-letter-spacing\@extra-large;
        }
    }

    &__shop {
        font-family: $font-family-sans-serif;
        font-size: $font-size-base;
        line-height: $room-shop-line-height;
        color: $color-primary;
        padding: $room-shop-padding\@small;
        margin: $room-shop-margin;

        @include mq($screen-m) {
            padding: $room-shop-padding\@medium;
            display: flex;

        }

        &-arrow {
            display: none;
            position: relative;

            svg {
                position: absolute;
                top: $room-shop-arrow-top-offset;
                width: $room-shop-arrow-width;
                margin-left: $spacer--small;
                fill: $color-primary;
            }

            @include mq($screen-m) {
                display: block;
            }
        }
    }
}
