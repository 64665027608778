.am-blog-pager {
    display: flex;
    flex-direction: row;

    &__pagination {
        white-space: nowrap;
    }

    &__link {
        .icon {
            fill: $color-primary;
        }

        &:before {
            content: none;
        }

        &:hover,
        &:focus,
        &.focus-visible {
            .icon {
                fill: $bright-sky-blue;
            }
        }
    }
}
