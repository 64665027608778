$fotorama-thumb-arr-background-width : $spacer--super-extra-large;
$fotorama-thumb-arr-background-height: $spacer--super-extra-large;
$fotorama-thumb-background-color     : $white;

.fotorama {
    &__stage {
        margin: 0 !important; // sass-lint:disable-line no-important
    }

    .fotorama__nav-wrap {
        .fotorama__thumb {
            background-color: $fotorama-thumb-background-color;
        }

        .fotorama__thumb__arr {
            background-color: $white;
            width: $fotorama-thumb-arr-background-width;
            height: $fotorama-thumb-arr-background-height;

            .fotorama__thumb--icon {
                margin: 0;
            }
        }
    }

    .fotorama__zoom-in-icon {
        display: none;
    }
}

.fotorama--fullscreen {
    .fotorama__nav-wrap {
        .fotorama__nav--thumbs {
            margin: 0 48px 0;
        }
    }
}

.gallery {
    position: relative;

    .download {
        &__button {
            display: none;
            float: right;
        }
    }
}
