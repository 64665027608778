.footer {
    &__newsletter {
        display: $footer__newsletter-display;
        flex-direction: column;
        align-items: center;

        @include mq($screen-m) {
            padding: $footer__newsletter-padding\@medium;
        }
    }

    &__links {
        @include mq($screen-m) {
            padding: $footer__links-padding\@medium;
        }

        .dropdown-list {
            &__item {
                padding: $footer__links-dropdown-list-item-padding;
            }
        }

        .list__item {
            padding: $footer__links-list-item-padding;

            @include mq($screen-xl) {
                min-width: $footer__links-list-item-min-width\@extra-large;
            }
        }
    }

    &__links-list {
        text-align: center;

        @include mq($screen-m) {
            justify-content: space-around;
        }
    }

    &__link {
        line-height: $footer__link-line-height;
    }

    &__social {
        @include mq($screen-m) {
            order: $footer__social-order\@large;
        }
    }

    &__social-list {
        order: $footer__social-list-order;

        @include mq($screen-m) {
            order: $footer__social-list-order\@medium;
        }

        .list__icon-link {
            background-color: $footer__social-list__icon-link-bg;

            .icon {
                fill: $footer__social-list__social-icon-fill;
            }

            &:before {
                background-color: $footer__social-list__icon-link-bg-hover;
            }

            &:hover,
            &:focus,
            &:focus-visible {
                .icon {
                    fill: $footer__social-list__social-icon-fill-hover;
                }
            }
        }
    }

    &__bottom-bar-handler {
        padding: $footer__bottom-padding;

        @include mq($screen-m) {
            padding: $footer__bottom-padding\@medium;
        }

        div[data-content-type="html"] {
            width: 100%;
            display: flex;
            flex-direction: column;

            @include mq($screen-m) {
                flex-direction: row;
                justify-content: space-between;
            }
        }
    }

    &__copyright {
        line-height: $footer__copywrite-line-height;
    }
}
