div[name="shippingAddress.custom_attributes.amorderattr_confirm_dock_checkbox"] {
    >.control {
        top: 7px;
        >.toggle {
            >.action-help {
                position: relative;
                top: -8px;
            }
        }
    }
}
input[name="custom_attributes[amorderattr_confirm_dock_checkbox]"] {
    width: 15px !important;
    margin-right: 5px !important;
}