$product-grid-item__bg-color                    : $white;

$product-grid-item__image-height--lazyload      : 100%;

$product-grid-item__image-wrapper-padding       : $spacer;

$product-grid-item__name-color                  : $gray-darker;
$product-grid-item__name-font-size\@extra-large : $font-size-large;
$product-grid-item__name-line-height            : 16px;
$product-grid-item__name-line-height\@medium    : 24px;
$product-grid-item__name-height                 : unset;
$product-grid-item__name-height\@small          : unset;
$product-grid-item__name-margin\@medium         : 0 0 2px;

$product-grid-item__price-min-height            : unset;

$product-grid-item__link-margin                 : $spacer--semi-large 0 0;

$product-grid-item__details-padding             : $spacer--medium 0 $spacer--extra-large;
$product-grid-item__details-padding\@medium     : $spacer--semi-medium 0 $spacer--semi-large;
$product-grid-item__details-margin              : 0 $spacer;
$product-grid-item__details-margin\@medium      : 0 $spacer--medium;
$product-grid-item__details-margin\@large       : 0 $spacer;
$product-grid-item__details-margin\@extra-large : 0 $spacer--semi-medium;
$product-grid-item__details-border              : $border-width-base $border-style-base $gray-light;
$product-grid-item__details-border-width        : 1px 0 0 0;
