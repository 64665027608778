// Mixins
@import '../Snowdog_Components/components/styles/mixins';

// Variables
@import '../Snowdog_Components/components/Atoms/variables/variables';
@import '../Snowdog_Components/components/Atoms/variables/gat-creek-next-variables';

// Components
@import '../Snowdog_Components/components/styles/non-critical';

// Theme partials
@import '../Amasty_GdprCookie/styles/module';
@import '../Amasty_Storelocator/styles/module';
@import '../Amasty_GiftCard/styles/module';
@import '../Magento_Ui/styles/module';
@import '../Magento_Catalog/styles/module';
@import '../Magento_Customer/styles/module';
@import '../MageMe_WebForms/styles/module';
@import '../Magento_PageBuilder/styles/module';
@import '../Magento_QuickOrder/styles/module';
@import '../Yotpo_Yotpo/styles/module';
@import '../Magento_Msrp/styles/module';
@import '../PayPal_Braintree/styles/module';
@import '../Magento_ReCaptchaFrontendUi/styles/module';
@import '../Magento_Theme/styles/module-non-critical';
@import '../Snowdog_RegistrationFields/styles/module';
@import '../Bss_Popup/styles/module';
@import '../Amasty_Orderattr/styles/module';
@import '../Amasty_Blog/styles/module';

// Show sections hidden in critical.scss
.page-main,
.footer {
    display: block !important;
}
