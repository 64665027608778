// specsheet page
.specsheet {
    .table {
        &--block {
            display: block;
            border: none;
            background: transparent;
            font-size: $font-size-base;

            tr, td, th {
                display: block;
                border: none;
                background: transparent;
            }

            td.data:before {
                content: none;
            }

            th[scope="row"] {
                width: 100%;
                padding: 0;
                color: $gray-darker;
                font-weight: $font-weight-bold;
            }

            th[scope="row"] + td {
                padding: $spacer--medium 0;
                flex-direction: column;
                color: $gray-darker;

                p {
                    font-size: $font-size-base;
                    color: $gray-darker;
                }
            }
        }
    }

}
