.am-blog-social {
    padding: $social-padding;
    display: flex;
    flex-direction: column;
    align-items: center;

    &__title {
        text-transform: uppercase;
        color: $gray-dark;
        font-size: $font-size-small;
        line-height: $social-title-line-height;
        margin: $social-title-margin;
    }

    &__link {
        background-color: $gray-dark;
        margin: $social-item-margin;
        width: $social-item-width;
        height: $social-item-height;
        display: flex;
        align-items: center;
        justify-content: center;

        &-icon {
            fill: $white;
            width: $social-icon-width;
            height: $social-icon-height;
        }

        &:hover,
        &:focus,
        &.focus-visible {
            background-color: $white;

            .icon {
                fill: $color-primary;
            }
        }
    }
}
