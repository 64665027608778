.section {
    &--categories {
        margin: $section-categories-margin\@small;

        @include mq($screen-m) {
            margin: $section-categories-margin\@medium;
        }

        @include mq($screen-l) {
            margin: $section-categories-margin\@large;
        }
    }

    &__heading {
        &--categories {
            margin: $heading-categories-margin;

            .heading--second-level {
                font-family: $font-family-sans-serif;
                letter-spacing: $heading-categories-letter-spacing\@small;
                font-size: $font-size-large;
                line-height: $heading-categories-line-hight;
                font-weight: $font-weight-normal;
                color: $color-primary;

                @include mq($screen-m) {
                    letter-spacing: $heading-categories-letter-spacing\@medium;
                    font-size: $font-size-extra-large;
                }
            }
        }
    }

    &--banner-additional {
        margin: $section-banner-additional;

        @include mq($screen-m) {
            margin: $section-banner-additional\@medium;
        }

        @include mq($screen-l) {
            margin: $section-banner-additional\@large;

            .section__content {
                flex-direction: column;
            }
        }

        @include mq($screen-xl) {
            .section__content {
                flex-direction: row;
            }
        }
    }

    &--teaser {
        margin: $section-teaser-margin;
    }
}
