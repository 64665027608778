.heading {
    &--first-level {
        @include mq($screen-m) {
            font-size: 40px;
        }

        @include mq($screen-l) {
            font-size: $font-size-super-extra-large;
        }
    }

    &--page {
        font-weight: $headings-font-weight--page;
        @include mq($screen-xl) {
            font-size: $heading-font-size--page\@screen-xl;
        }
    }
}
