.order-summary {
    &__wrapper {
        background: $white;
        padding: $spacer--semi-medium $spacer--extra-large;
    }

    &__form {
        .field[name='shippingAddress.country_id'] {
            display: none;
        }
    }

    &__buttons {
        display: flex;
        align-items: center;
    }

    &__button {
        text-decoration: underline;
        padding: 0 $spacer--semi-medium 0 0;
        font-weight: $font-weight-normal;

        &:hover,
        &.focus-visible {
            text-decoration: underline;
            color: $color-primary;
        }
    }

    &__title {
        color: $gray-darkest;
    }

    &__subtitle {
        display: flex;
        justify-content: space-between;
        color: $gray-darkest;
    }

    &__title-total {
        color: $gray-darkest;
    }

    &__text {
        color: $color-secondary;
        font-size: $font-size-base;
    }

    &__text-accent {
        color: $gray-darkest;
        font-weight: $font-weight-medium;
    }

    &__radio-label {
        display: inline-flex;
        flex-direction: column;
    }

    &__collapsible {
        &--active {
            .icon {
                transform: rotate(180deg);
            }
        }
    }

    &__actions {
        background: $white;
        padding: 0 $spacer--extra-large $spacer--semi-medium $spacer--extra-large;
    }

    &__divider {
        &--no-margin {
            margin: 0;
        }
    }

    &__modal-title {
        font-size: 40px;
        text-align: center;
    }

    &__modal-fields {
        display: flex;
        align-items: flex-end;
        justify-content: center;
        margin: 0 0 $spacer--large;

        .input {
            min-width: $order-summary__modal-input-min-width;
            margin: 0;

            .input__field {
                &[name='postcode'] {
                    border-radius: 0;
                }
            }
        }

        .field {
            position: relative;
        }

        .mage-error,
        .mage-notice {
            position: absolute;
            top: 100%;
            left: 0;
        }
    }

    &__modal-subtitle {
        color: $gray-darkest;
        font-family: $font-family-base;
        font-weight: $font-weight-medium;
        text-align: center;
        margin: 0 0 $spacer--semi-medium;
    }

    &__modal-text {
        color: $font-color-base;
        font-size: $font-size-base;
        text-align: center;
        margin-bottom: $spacer--extra-large;
    }

    &__modal-content {
        padding: $spacer--extra-large $spacer--extra-large $spacer--medium;
    }

    &__tab-icon {
        fill: $gray-dark;
    }

    &__product-name {
        color: $gray-darkest;
        margin: 0 0 $spacer;
    }

    &__product-info {
        .price {
            font-weight: $font-weight-medium;
        }
    }
}
