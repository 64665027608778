.store-locator-layout {
    &#maincontent { // sass-lint:disable-line no-ids
        margin-top: $layout-content-margin-top;
        margin-bottom: $layout-content-margin-bottom;

        @include mq($screen-m) {
            margin-top: $layout-content-margin-top\@medium;
        }

        @include mq($screen-l) {
            margin-top: $layout-content-margin-top\@large;
        }
    }
}
