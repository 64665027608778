.catalog-list {
    font-family: $catalog-list__font-family;

    @include mq($screen-m) {
        margin: $catalog-list__margin\@medium;
    }

    @include mq($screen-l) {
        margin: $catalog-list__margin\@large;
    }

    @include mq($screen-xl) {
        margin: $catalog-list__margin\@extra-large;
    }

    &__banner {
        margin: $catalog-list__banner-margin;
    }

    &__sidebar {
        @include mq($screen-xl) {
            margin: $catalog-list__sidebar-margin\@xl;
        }
    }

    &__category-description {
        padding: $spacer 0 0;
        color: $gray-darkest;
        line-height: 1.71;

        @include mq($screen-m) {
            padding: $spacer $spacer--semi-medium 0;
            font-size: $font-size-medium;
            line-height: 2;
        }

        @include mq($screen-l) {
            padding: $spacer 0 0;
        }


        @include mq($screen-xl) {
            max-width: $screen-m;
            margin: 0 auto;
        }

        p {
            color: $gray-darkest;

            &:not(:last-child) {
                padding-bottom: $spacer;
            }
        }
    }
}


