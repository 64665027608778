//
//  GDPR Cookie Compliance
//  ____________________________________________

//
//  Variables
//  _____________________________________________

$amgdprcookie-text__color: #363636;
$amgdprcookie-btn__background: #ef7c4a;
$amgdprcookie-btn__text: #fff;
$amgdprcookie-container__background: #fff;
$amgdprcookie-toogle-disable__background: #e8e8e8;
$amgdprcookie-scroll__color: #e0e0e0;
$amgdprcookie-scroll__background: $amgdprcookie-toogle-disable__background;
$amgdprcookie-toogle-normal__background: #a5a5a5;
$amgdprcookie-toogle__length: 25px;
$amgdprcookie-toogle__padding: 2px;
$amgdprcookie-toogle-checked__background: #1979c3;
$amgdprcookie-link__hover__color: #1787e0;
$amgdprcookie-link__color: #5384ec;
$amgdprcookie-link__base_color: #389dfe;
$amgdprcookie-button__focus: 0 0 3px 1px #00699d;
$amgdprcookie-btn-done__highlight: #1357e6;
$amgdprcookie-popup__shadow: #0a6c9f;
$amgdprcookie-btn-close-icon__background-image: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTciIGhlaWdodD0iMTciIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGNpcmNsZSBjeD0iOC41IiBjeT0iOC41IiByPSI4IiBzdHJva2U9IiNDQUNBQ0EiLz48cGF0aCBkPSJNNS4yNSA1LjI1bDYuNSA2LjVtMC02LjVsLTYuNSA2LjUiIHN0cm9rZT0iI0NBQ0FDQSIvPjwvc3ZnPg==');
