// // container
// $product-view__max-width\@large                          : 1120px !default;
$product-view__padding                            : $spacer;
$product-view__padding\@medium                    : $spacer--semi-medium;
$product-view__padding\@large                     : $spacer--extra-large;
$product-view__padding\@xl                        : $spacer--semi-large;
$product-view__margin-top                         : $spacer;
$product-view__margin-bottom                      : $spacer--extra-large;
$product-view__margin-top\@medium                 : $spacer--semi-medium;
$product-view__margin-bottom\@medium              : $spacer--super-extra-large;
$product-view__margin-top\@large                  : $spacer;
$product-view__margin-top\@xl                     : $spacer--extra-small;
$product-view__bg                                 : $white;

// wrapper / flex & grid
$product-view__wrapper-margin                     : 0 auto $spacer--super-extra-large;
$product-view__wrapper-max-width                  : unset;
$product-view__column-gap\@large                  : $spacer--medium;
$product-view__column-gap\@xl                     : 88px;
$product-view__details-desc-width\@large          : calc(100% - #{$product-view__column-gap\@large});
$product-view__details-desc-width\@xl             : calc(100% - #{$product-view__column-gap\@xl});

// gallery

// title
$product-view__title-margin                       : 0 0 $spacer;
$product-view__title-margin\@medium               : 0;
$product-view__title-margin\@xl                   : 0 0 $spacer;
$product-view__title-font-size                    : $font-size-extra-large;
$product-view__title-font-size\@large             : $font-size-super-large;
$product-view__title-font-size\@xl                : 40px;
$product-view__title-line-height                  : 32px;
$product-view__title-line-height\@medium          : 48px;
$product-view__title-font-weight                  : $font-weight-normal;

// rating
$product-view__rating-padding                     : 0;
$product-view__rating-margin                      : 0 0 $spacer--large;
$product-view__rating-margin\@medium              : 0 0 $spacer--semi-medium;

// price
$product-view__price-display                      : block;
$product-view__price-padding                      : 0;
$product-view__price-margin                       : 0 0 $spacer--semi-medium;
$product-view__price-value-font-size              : $font-size-extra-large;
$product-view__price-value-font-size\@medium      : $font-size-super-large;
$product-view__price-value-font-size--old         : $font-size-medium;
$product-view__price-value-font-size--old\@medium : $font-size-extra-large;
$product-view__price-value-color                  : $color-secondary;
$product-view__price-value-font-weight            : $font-weight-normal;
$product-view__price-value-line-height            : 32px;

// short description
$product-view__short-description-color            : $gray-darker;
$product-view__short-description-font-size        : $font-size-base;
$product-view__short-description-line-height      : 24px;

// buttons
$product-view__buttons-wrapper-flex\@large        : 1;
$product-view__buttons-width\@large               : 100%;
$product-view__buttons-padding-bottom             : $spacer--medium;
$product-view__buttons-padding-bottom\@large      : $spacer--semi-medium;

// button
$product-view__button-min-height                  : 56px;

$product-view__button-wishlist-width              : auto;
$product-view__button-wishlist-display            : flex;
$product-view__button-contact-margin              : 0 0 $spacer;
$product-view__button-contact-margin\@medium      : 0 $spacer 0 0;
$product-view__button-additional-width            : 100%;
$product-view__button-additional-width\@medium    : auto;

// add to cart button
$product-view__add-to-cart                        : inline-flex;

// qty
$product-view__qty-margin                         : 0 0 $spacer--medium;
$product-view__qty-margin\@large                  : 0 $spacer $spacer--semi-medium 0;

// form
$product-view__form-padding                       : 0;
$product-view__form-border                        : none;
$product-view__form-margin                        : $spacer--semi-medium 0 0;

$product-view__tabs-title-padding                 : 0 0 0 $spacer--medium;

// error
$product-view__error-margin                       : $spacer--extra-small 0 0;
$product-view__error-color                        : $color-error;
$product-view__error-font-family                  : $font-family-sans-serif;

// made to order
$product-view__made-to-order-font-family          : $font-family-sans-serif;
$product-view__made-to-order-font-size            : $font-size-base;
$product-view__made-to-order-color                : $color-primary;
$product-view__made-to-order-line-height          : 48px;
$product-view__made-to-order-border               : none;
