$dashboard-margin\@medium                      : 0 $spacer--semi-medium;
$dashboard-margin\@large                       : $spacer--semi-large $spacer--extra-large 0;
$dashboard-margin\@extra-large                 : 0 auto;

$dashboard-sidebar-max-width                   : 29%;
$dashboard-sidebar-flex-basis                  : 29%;
$dashboard-sidebar-padding\@large              : 0 $spacer--medium 0 0;

$dashboard-contnet-max-width                   : 100% - $dashboard-sidebar-max-width;
$dashboard-contnet-max-flex-basis              : 100% - $dashboard-sidebar-flex-basis;
$dashboard-content-top-container-display       : flex;
$dashboard-content-top-container-justify       : space-between;
$dashboard-content-top-container-align         : center;
$dashboard-content-top-container-margin        : $spacer--super-large 0;
$dashboard-content-top-container-margin\@large : 0 0 $spacer--super-large;
$dashboard-content-heading-padding             : $spacer--medium 0 0;
$dashboard-content-heading-font-size           : 40px;
$dashboard-content-heading-letter-spacing      : -0.17px;
$dashboard-content-screen-size-threshold       : 1344px;

$dashboard-link-letter-spacing                 : 1px;
$dashboard-link-padding                        : 0;

$dashboard-list-current-item-padding           : $spacer;
$dashboard-list-arrow-transform                : rotate(-45deg) translateY(-50%);
$dashboard-list-arrow-position                 : 50%;

$dashboard-nav-button-color                    : $font-color-base;
$dashboard-nav-button-padding                  : 0 $spacer--medium;
$dashboard-nav-button-min-width                : 120px;
$dashboard-nav-button-width                    : 32%;
$dashboard-nav-content-max-width               : 320px;
$dashboard-nav-actions-margin                  : $spacer--semi-medium 0 80px;
$dashboard-nav-section-sidebar-margin          : 0 0 80px;
$dashboard-nav-section-heading-margin          : 0 0 $spacer--large;
$dashboard-nav-section-heading-letter-spacing  : 1px;
$dashboard-nav-section-message-line-height     : 2;
$dashboard-nav-section-message-letter-spacing  : 0.88px;

$dashboard-items-margin-bottom                 : 72px;
