// sass-lint:disable no-vendor-prefixes
$tab-gallery__items-margin           : 0 0 $spacer--medium;
$tab-gallery__item-margin            : $spacer--extra-small $spacer;
$tab-gallery__link-margin            : 0 $spacer--extra-small;
$tab-gallery__link-padding           : 0 $spacer;
$tab-gallery__image-width            : 200px;
$tab-gallery__scrollbar-height       : $spacer;
$tab-gallery__scrollbar-thumb-radius : $spacer--extra-small;

$tab-gallery__video-height           : 40px;
$tab-gallery__video-top              : 10px;
$tab-gallery__video-width            : 49px;

.tab-gallery {
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;

    &__items {
        width: max-content;
        justify-content: flex-start;
        display: flex;
        margin: $tab-gallery__items-margin;
    }

    &__item {
        display: flex;
        margin: $tab-gallery__item-margin;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    &__link {
        padding: $tab-gallery__link-padding;
        border: $form-elements-border;
        display: flex;
        align-items: center;
        position: relative;

        &--video {
            &:after {
                background: url(../Magento_ProductVideo/img/gallery-sprite.png) bottom left;
                bottom: 0;
                content: '';
                height: $tab-gallery__video-height;
                left: 0;
                margin: auto;
                position: absolute;
                right: 0;
                top: $tab-gallery__video-top;
                width: $tab-gallery__video-width;
            }
        }
    }

    &__image {
        width: $tab-gallery__image-width;
        display: block;
    }

    &__caption {
        display: none;
    }

    &::-webkit-scrollbar {
        display: inherit;
        height: $tab-gallery__scrollbar-height;
    }

    &::-webkit-scrollbar-thumb {
        border-radius: $tab-gallery__scrollbar-thumb-radius;
        background: $blue;
    }
}
