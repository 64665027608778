$toolbar__padding           : 0;
$toolbar__padding\@medium   : 0;
$toolbar__padding\@large    : 0;
$toolbar__border-style      : none;
$toolbar__margin            : $spacer--medium 0 $spacer;
$toolbar__margin\@medium    : $spacer--semi-medium 0 $spacer;
$toolbar__margin\@large     : 0 0 $spacer;

$toolbar__sorter-padding    : 0;
$toolbar__sorter-margin     : $spacer 0;
$toolbar__sorter-order      : 0;

$toolbar__limiter-margin    : 0;

$toolbar__label-min-width   : 50px;
$toolbar__label-align       : center;

$toolbar__dropdown-bg       : transparent;
