.locator-filter {
    font-family: $font-family-sans-serif;
    margin: auto;

    &__title {
        text-align: center;
        font-size: $filter-title-font-size;
        color: $color-primary;
        letter-spacing: $filter-title-letter-spacing;
        line-height: $filter-title-line-height;
        text-transform: uppercase;
        margin: $filter-title-margin;

        @include mq($screen-xl) {
            font-size: $filter-title-font-size\@extra-large;
            letter-spacing: $filter-title-letter-spacing\@extra-large;
        }
    }

    &__content:last-child {
        display: flex;
        margin-bottom: $spacer--semi-medium;
        max-width: $filter-content-max-width;
    }

    &__checkbox {
        &-label {
            color: $font-color-base;
            font-size: $filter-checkbox-labe-font-size;
            line-height: $spacer--extra-large;

            &::after {
                background-color: $black;
            }
        }
    }

    &__input {
        margin-bottom: $filter-input-margin-bottom;

        &-text {
            color: $font-color-base;
            width: 100%;
            height: $spacer--extra-large;
            border: $border-base;
            border-radius: $filter-input-text-border-radius;
            padding: $filter-input-text-padding;
            margin: 0;

            &::placeholder {
                color: $font-color-base;
            }
        }
    }

    &__action {
        &-button {
            width: 100%;
            letter-spacing: $filter-action-button-letter-spacing;
            color: $gray-lighter;
            max-width: $filter-action-button-max-width;
        }
    }
}
