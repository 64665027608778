.table-builder {
    .stepped-progress {
        font-size: 14px;
        margin-left: auto;
        margin-right: auto;

        label {
            color: #848484;
        }

        .tabs-wrapper {
            align-items: flex-end;
            background-color: $gray-light;
            display: flex;
            margin-top: 40px;
            padding: 15px;

            .tab-title {
                align-items: center;
                color: $gray-darker;
                display: flex;
                flex-direction: column;
                width: 100%;
                text-align: center;

                &.-completed,
                &.-current {
                    color: $color-primary;
                }

                .marker {
                    border-radius: 50%;
                    display: inline-block;
                    height: 24px;
                    margin-top: 5px;
                    position: relative;
                    width: 100%;

                    &::before {
                        background: $color-primary;
                        content: '';
                        display: inline-block;
                        height: 10px;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 10px;
                    }

                    &::after {
                        background: $color-primary;
                        content: '';
                        display: inline-block;
                        height: 1px;
                        left: calc(50% + 5px);
                        position: absolute;
                        top: 12px;
                        transform: translateY(-50%);
                        width: calc(100% - 9px);
                    }
                }

                &.-current .marker {
                    &::before {
                        background: white;
                        border: 1px solid $color-primary;
                    }

                    .active {
                        background: $color-primary;
                        content: '';
                        display: inline-block;
                        height: 15px;
                        left: 50%;
                        position: absolute;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 15px;
                    }
                }

                &.-completed .marker {
                    &::before {
                        background: $color-primary;
                        border: 1px solid $color-primary;
                    }

                    &::after {
                        background: $color-primary;
                    }
                }

                &.-current,
                &.-completed {
                    .title {
                        color: $color-primary;
                    }

                    .marker {
                        $size: 24px;

                        &::before {
                            height: $size;
                            width: $size;
                        }

                        &::after {
                            left: calc(50% + (#{$size} / 2) );
                            width: calc(100% - (#{$size} / 2) );
                        }
                    }
                }

                &:last-child {
                    &.-current .marker::before {
                        background: $color-primary;
                    }

                    .marker .active,
                    .marker::after {
                        display: none;
                    }
                }
            }
        }

        .content-wrapper {
            $padding: 30px;
            $padding-with-safety-margin: ($padding + 5px);
            margin: 40px auto 0;
            max-width: 400px;
            overflow: hidden;
            position: relative;

            & > * {
                transition-duration: 0.5s;
            }

            & > :not(.-current) {
                position: absolute;
                top: $padding;
                visibility: hidden;
                width: calc(100% - (#{$padding} * 2));
            }

            & > .-completed {
                transform: translateX(calc(-100% - #{$padding-with-safety-margin}));
            }

            & > .-pending {
                transform: translateX(calc(100% + #{$padding-with-safety-margin}));
            }

            .steps-navigation-wrapper {
                align-items: center;
                display: grid;
                grid-auto-flow: column;
                justify-content: space-between;
                flex-wrap: wrap;
                grid-gap: 15px;
            }

            div.mage-error {
                color: #d8449f;
                margin-bottom: 15px;
            }

            .field > .label {
                display: inline-grid;
                grid-auto-flow: column;
                grid-gap: 10px;
                justify-content: flex-start;
                align-items: center;
                margin-bottom: 15px;
            }

            .field > .description-text {
                display: inline-block;
                float: right;

                .swatch__sample-link {
                    line-height: 20px;
                }
            }
        }

        .options-list.nested {
            display: grid;
            grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
            grid-gap: 15px;

            .radio__text {
                width: 100%;
            }
        }
    }

    &__addTo {
        display: flex;
        align-items: flex-end;
        margin-top: 20px;

        .towishlist {
            display: none;
        }
    }

    &__actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 25px;

        .button[data-trigger="previous-step"]::after {
            transform: translateY(-50%) rotatey(180deg);
        }

        .button[data-trigger="next-step"] {
            max-width: 145px;
            margin-left: auto;
        }
    }
}
