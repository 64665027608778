.range-filter {
    &__field {
        color: $range__field-color;
        font-family: $range__field-font-family;
    }
}

.noUi-connects {
    height: $range__track-height--active;
}
