$swatch__margin                   : 0 0 $spacer--semi-medium;

$swatch__title-margin             : 0;

$swatch__option-margin            : 0 $spacer $spacer--small 0;
$swatch__option-padding           : 2px;
$swatch__option-border            : 1px solid $white;
$swatch__option-border-width      : 1px;
$swatch__option-border--size      : none;

$swatch__finish-type-border-width : 2px;
$swatch__with-text-padding        : $spacer--extra-small $spacer;

// tooltips
$swatch-tooltip__max-width        : 140px;
$swatch-tooltip__bg-color         : $color-primary;
$swatch-tooltip__text-color       : $white;
$swatch-tooltip__corner-size      : 8px;
