.cart-list-item {
    &__action-icon {
        &.icon {
            fill: $color-secondary;
        }
    }

    &__name {
        display: flex;
        align-items: center;
        height: 48px;
        color: $gray-darkest;
    }
}
