.dashboard {
    &#maincontent { // sass-lint:disable-line no-ids
        margin-top: $spacer--medium;

        @include mq($screen-l) {
            margin-top: $spacer--semi-large;
        }
    }

    @include mq($screen-m) {
        margin: $dashboard-margin\@medium;
    }

    @include mq($screen-l) {
        margin: $dashboard-margin\@large;
    }

    @include mq($dashboard-content-screen-size-threshold) {
        margin: $dashboard-margin\@extra-large;
    }


    &__sidebar {
        @include mq($screen-l) {
            max-width: $dashboard-sidebar-max-width;
            flex-basis: $dashboard-sidebar-flex-basis;
            padding: $dashboard-sidebar-padding\@large;
        }
    }

    &__link,
    .actions-group__link {
        letter-spacing: $dashboard-link-letter-spacing;
        padding: $dashboard-link-padding;
    }

    &-nav {
        &__mobile {
            font-size: $font-size-medium;
            color: $gray-darkest;
            padding: 0;
            line-height: $spacer--super-large;

            &--active {
                border: none;
            }
        }

        &__actions {
            display: none;
            max-width: $dashboard-nav-content-max-width;

            @include mq($screen-l) {
                display: block;
                margin: $dashboard-nav-actions-margin;
            }
        }

        &__button {
            background-color: transparent;
        }

        &__section {
            .sidebar-block ~ .sidebar-block {
                margin: 0;
            }
            .sidebar-block {
                padding: 0;
                margin: $dashboard-nav-section-sidebar-margin;

                &__heading {
                    margin: $dashboard-nav-section-heading-margin;

                    .dashboard-nav__section-title  {
                        color: $font-color-base;
                        text-transform: uppercase;
                        font-size: $font-size-medium;
                        letter-spacing: $dashboard-nav-section-heading-letter-spacing;
                    }
                }

                &__message {
                    font-size: $font-size-base;
                    line-height: $dashboard-nav-section-message-line-height;
                    letter-spacing: $dashboard-nav-section-message-letter-spacing;
                }
            }
        }

        &__section ~ .dashboard-nav__section {
            .sidebar-block {
                margin: 0;
            }
        }

        &__content {
            @include mq($screen-l) {
                max-width: $dashboard-nav-content-max-width;
            }

            &--visible {
                padding-bottom: $spacer--semi-medium;
                border-bottom: $border-base;
            }

            .list {
                .current {
                    display: none;
                    padding: $dashboard-list-current-item-padding;
                    font-size: $font-size-medium;
                    line-height: $spacer--super-large;


                    @include mq($screen-l) {
                        display: list-item;
                    }

                    @include mq($screen-xl) {
                        padding-left: $spacer--semi-medium;
                    }

                    > * {
                        font-weight: $font-weight-bold;
                    }

                    &::after {
                        transform: $dashboard-list-arrow-transform;
                        top: $dashboard-list-arrow-position;
                    }
                }

                .item {
                    &:not(.current) > a {
                        padding: 0;
                        color: $font-color-base;
                        font-size: $font-size-medium;
                        line-height: $spacer--super-large;

                        @include mq($screen-l) {
                            padding: $spacer;
                        }

                        @include mq($screen-xl) {
                            padding-left: $spacer--semi-medium;
                        }
                    }
                }
            }
        }
    }

    &__content {
        &-top-container {
            display: $dashboard-content-top-container-display;
            justify-content: $dashboard-content-top-container-justify;
            align-items: $dashboard-content-top-container-align;
            margin: $dashboard-content-top-container-margin;

            @include mq($screen-l) {
                margin: $dashboard-content-top-container-margin\@large;
            }

            .dashboard-nav__button {
                color: $dashboard-nav-button-color;
                padding: $dashboard-nav-button-padding;
                min-width: $dashboard-nav-button-min-width;
                width: $dashboard-nav-button-width;
            }

            .dashboard__content-heading {
                padding: $dashboard-content-heading-padding;
                color: $font-color-base;

                @include mq($screen-m) {
                    font-size: $dashboard-content-heading-font-size;
                    letter-spacing: $dashboard-content-heading-letter-spacing;
                }

                @include mq($screen-l) {
                    padding: 0;
                }
            }
        }

        @include mq($screen-m) {
            > .dashboard-items {
                margin-bottom: $dashboard-items-margin-bottom;
            }
        }

        @include mq($screen-l) {
            max-width: $dashboard-contnet-max-width;
            flex-basis: $dashboard-contnet-max-flex-basis;
        }

        .dashboard-form {
            &__title {
                color: $font-color-base;
            }
        }

        .dashboard-items {
            &__title {
                color: $font-color-base;
                max-height: $spacer--super-large;
            }

            &__item {
                color: $font-color-base;

                &-content {
                    font-size: $spacer--medium;
                    line-height: $spacer--large;
                }
            }

            &__subtitle {
                color: $font-color-base;
            }

            .dashboard-table__header {
                max-height: $spacer--super-large;

                .dashboard-table {
                    &__title {
                        color: $font-color-base;
                    }

                    &__link {
                        display: none;

                        @include mq ($screen-m) {
                            display: flex;
                        }
                    }
                }
            }
        }
    }
}
