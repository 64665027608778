.filter {
    &__heading {
        font-weight: $filter__heading-font-weight;
        line-height: $filter__heading-line-height;
        letter-spacing: 1px;
        color: $filter__heading-color;
    }

    &--range {
        border-bottom: none;
    }

    &__content {
        overflow: visible;
    }

    .tooltip {
        position: relative;

        &__content-wrapper {
            top: 48px;
            bottom: unset;
            transform: unset;

            @include mq($screen-l) {
                transform: translate(-50%);
            }
        }

        &--is-open:after {
            bottom: unset;
            top: calc(100% - 8px);
        }
    }
}
