$section-categories-margin\@small         : 0 0 $spacer--semi-large;
$section-categories-margin\@medium        : 0 $spacer--extra-large $spacer--semi-large;
$section-categories-margin\@large         : 0 0 $spacer--semi-large;

$section-banner-additional                : 0 0 $spacer--extra-large;
$section-banner-additional\@medium        : 0 $spacer--extra-large $spacer--extra-large;
$section-banner-additional\@large         : 0 0 $spacer--extra-large;

$heading-categories-margin                : 0 0 $spacer--semi-medium;
$heading-categories-letter-spacing\@small : 3px;
$heading-categories-letter-spacing\@medium: 4px;
$heading-categories-line-hight            : $spacer--extra-large;

$section-teaser-margin                    : 0 0 $spacer--super-extra-large;
