.product-view__options {
    .ui-slider {
        margin: 20px;
        .ui-slider-handle {
            width: 22px;
            height: 22px;
            text-align: center;
            top: -5px;
            cursor: grab;
            color: #fff;
            background: #005096;
        }
    }
}
