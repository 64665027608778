$room-nav-title-letter-spacing              : -0.1px;
$room-nav-title-line-height                 : 2;
$room-nav-title-margin                      : $spacer--extra-large 0 $spacer--large;
$room-nav-title-margin\@large               : $spacer--extra-large 0 $spacer--extra-large;
$room-nav-title-font-size\@medium           : 40px;
$room-nav-title-font-size\@extra-large      : $font-size-super-extra-large;
$room-nav-title-latter-spacing              : -0.17px;
$room-nav-items-margin                      : 0 0 $spacer--large;
$room-nav-item-max-width                    : 277px;
$room-nav-item-max-width\@large             : 100%;
$room-nav-link-line-height                  : 72px;
$room-nav-link-letter-spacing               : 1px;
$room-nav-link-max-height                   : 72px;

$room-image-margin                          : 0 0 $spacer--medium;
$room-title-letter-spacing\@small           : -0.33px;
$room-title-letter-spacing\@medium          : -0.38px;
$room-title-letter-spacing\@extra-large     : -0.5px;
$room-title-padding\@small                  : 0 $spacer 0;
$room-title-padding\@medium                 : 0 $spacer--medium 0;
$room-title-line-height                     : 1.78;
$room-title-line-height\@extra-large        : 1.33;
$room-shop-line-height                      : 3.43;
$room-shop-padding\@small                   : 0 $spacer 0;
$room-shop-padding\@medium                  : 0 $spacer--medium 0;
$room-shop-margin                           : 0 0 $spacer--medium;
$room-shop-arrow-top-offset                 : 30%;
$room-shop-arrow-width                      : $spacer--semi-medium;

$room__banner-heading-font-size             : $font-size-large;
$room__banner-heading-font-size\@medium     : $font-size-extra-large;
$room__banner-heading-font-size\@large      : $font-size-super-large;
